import Vue from 'vue'
import router from "./router"
import i18n from "./i18n";

import App from './App.vue'
import VueLazyload from 'vue-lazyload'

import axios from 'axios'
import VueAxios from 'vue-axios'

import VueSmoothScroll from 'vue2-smooth-scroll'
Vue.use(VueSmoothScroll)

import VueGtag from "vue-gtag";
Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_MEASUREMENT_ID }
});

Vue.config.productionTip = false

Vue.use(VueLazyload)
Vue.use(VueAxios, axios)
Vue.prototype.$axios = VueAxios
Vue.prototype.$hostApi = process.env.VUE_APP_HOST_API
Vue.prototype.$appsApi = process.env.VUE_APP_APPS_URL
Vue.prototype.$blogApi = process.env.VUE_APP_BLOG_URL
new Vue({
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')
