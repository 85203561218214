import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const routes = [{
    path: '/',
    component: () =>
        import("@/views/Home.vue")
},
{
    path: '/wasteup',
    component: () => import("@/views/Wasteup.vue")
},
{
    path: '/term-and-condition',
    name: 'TermAndCondition',
    component: () =>
        import('@/views/term-and-condition/index.vue')
},
{
    path: '/career',
    name: 'Career',
    component: () =>
        import('@/views/career/index.vue')
}
]
const router = new Router({
    mode: "history",
    routes
})
export default router